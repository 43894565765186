import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiMongodb,
  DiGit,
} from "react-icons/di";
import {
  SiRedis,
  SiFirebase,
  SiPostgresql,
  SiTailwindcss,
  SiPhp,
  SiJest,
  SiWordpress,
} from "react-icons/si";
import { TbBrandGolang, TbBrandSocketIo } from "react-icons/tb";



function Techstack() {
  return (
    <Row
      style={{
        justifyContent: "center",
        paddingBottom: "50px",
      }}
    >
      <Col xs={4} md={2} className="tech-icons">
        <a
          href="https://developer.mozilla.org/fr/docs/Web/JavaScript"
          target="_blank"
          style={{ color: "white" }}
          rel="noreferrer"
        >
          <DiJavascript1 />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a
          href="https://jestjs.io/fr/"
          target="_blank"
          style={{ color: "white" }}
          rel="noreferrer"
        >
          <SiJest />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a
          href="https://www.php.net/"
          target="_blank"
          style={{ color: "white" }}
          rel="noreferrer"
        >
          <SiPhp />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a
          href="https://tailwindcss.com/"
          target="_blank"
          style={{ color: "white" }}
          rel="noreferrer"
        >
          <SiTailwindcss />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a
          href="https://golang.org/"
          target="_blank"
          style={{ color: "white" }}
          rel="noreferrer"
        >
          <TbBrandGolang />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a
          href="https://nodejs.org/en/"
          target="_blank"
          style={{ color: "white" }}
          rel="noreferrer"
        >
          <DiNodejs />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a
          href="https://reactjs.org/"
          target="_blank"
          style={{ color: "white" }}
          rel="noreferrer"
        >
          <DiReact />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a
          href="https://socket.io/"
          target="_blank"
          style={{ color: "white" }}
          rel="noreferrer"
        >
          <TbBrandSocketIo />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a
          href="https://www.mongodb.com/"
          target="_blank"
          style={{ color: "white" }}
          rel="noreferrer"
        >
          <DiMongodb />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a href="https://git-scm.com/" target="_blank" style={{ color: "white" }} rel="noreferrer">
          <DiGit />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a href="https://firebase.google.com/" target="_blank" style={{ color: "white" }} rel="noreferrer">
          <SiFirebase />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a href="https://redis.io/" target="_blank" style={{ color: "white" }} rel="noreferrer">
          <SiRedis />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a href="https://www.postgresql.org/" target="_blank" style={{ color: "white" }} rel="noreferrer">
         <SiPostgresql />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a href="https://wordpress.org/" target="_blank" style={{ color: "white" }} rel="noreferrer">
          <SiWordpress />
        </a>
      </Col>
    </Row>
  );
}

export default Techstack;
