import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import gpt from "../../Assets/Projects/gpt.jpeg";
import hoquet from "../../Assets/Projects/hoquet.png";
import QuizzBattle from "../../Assets/Projects/quizzbattle.jpg";
import estimateur from "../../Assets/Projects/estimateur.png";
import faq from "../../Assets/Projects/faq.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={hoquet}
              isBlog={false}
              title="Hoquet Business B2B"
              description="Hoquet Business B2B is a comprehensive website I developed using WordPress, tailored specifically for business-to-business interactions. It features advanced email management systems that facilitate seamless communication with clients and partners. The site also showcases a detailed presentation of our franchise, providing prospective franchisees with all the information they need to make informed decisions."
              // ghLink="https://github.com/soumyajit4419/Chatify"
              demoLink="https://business.guy-hoquet.com/affilier-cabinet-transactions-commerces-immobilier-dentreprise/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={QuizzBattle}
              isBlog={false}
              title="QuizzBattle"
              description="Our group project involved creating a quiz website where users can create quizzes, manage scores, rankings, badges, emotes, and more, utilizing technologies like Node.js, Vue.js, Tailwind CSS, and real-time updates."
              ghLink="https://github.com/js-suck/QuizzBattle/tree/main"
              // demoLink="https://blogs.soumya-jit.tech/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={estimateur}
              isBlog={false}
              title="Online Real Estate Estimator"
              description="The new online estimator site is designed to provide an interactive and user-friendly experience, leveraging the core web technologies JavaScript, HTML, and CSS to create a responsive frontend. The backend, powered by PHP, handles data processing and server-side logic, ensuring efficient communication between the user interfaces and the database. Additionally, the site integrates Brevo for email management."
              // ghLink="https://github.com/soumyajit4419/Editor.io"
              demoLink="https://estimateur.guy-hoquet.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={gpt}
              isBlog={false}
              title="Smart Recipe AI"
              description="Development of a web application for searching cooking recipes with intelligent recipe recommendation, while exploring advanced features that can be enhanced through artificial intelligence."
              ghLink="https://github.com/Choetsu/SmartRecipe"
              // demoLink="https://plant49-ai.herokuapp.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={faq}
              isBlog={false}
              title="FAQ Redesign for Guy Hoquet"
              description="The first site I developed during my apprenticeship was a project to redesign the FAQ for Guy Hoquet, done with WordPress. This redesign aimed to modernize the user interface and improve the accessibility of information for users. Using WordPress, a flexible and robust platform, I was able to create a well-structured site that facilitates navigation and the search for specific information. This project allowed me to apply my web design skills while meeting the specific needs of the company in terms of customer support and content management."
              // ghLink="https://github.com/soumyajit4419/AI_For_Social_Good"
              demoLink="https://faq.guy-hoquet.com/"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
