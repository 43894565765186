import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiVisualstudiocode,
  SiPostman,
  SiMacos,
  SiMicrosoft,
  SiMicrosoftazure,
  SiAndroidstudio,
  SiDocker,
} from "react-icons/si";

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <a
          href="https://www.microsoft.com/fr-fr"
          target="_blank"
          style={{ color: "white" }}
          rel="noreferrer"
        >
          <SiMicrosoft />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a
          href="https://www.apple.com/fr/macos"
          target="_blank"
          style={{ color: "white" }}
          rel="noreferrer"
        >
          <SiMacos />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a
          href="https://code.visualstudio.com/"
          target="_blank"
          style={{ color: "white" }}
          rel="noreferrer"
        >
          <SiVisualstudiocode />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a
          href="https://www.postman.com/"
          target="_blank"
          style={{ color: "white" }}
          rel="noreferrer"
        >
          <SiPostman />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a
          href="https://www.microsoft.com/fr-fr/security/business/identity-access/microsoft-entra-id"
          target="_blank"
          style={{ color: "white" }}
          rel="noreferrer"
        >
          <SiMicrosoftazure />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a
          href="https://developer.android.com/studio"
          target="_blank"
          style={{ color: "white" }}
          rel="noreferrer"
        >
          <SiAndroidstudio />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a
          href="https://www.docker.com/"
          target="_blank"
          style={{ color: "white" }}
          rel="noreferrer">
          <SiDocker />
        </a>
      </Col>
    </Row>
  );
}

export default Toolstack;
